import { CmsComponent } from '@/components/CmsInterfaces';
import replaceAbbreviations from '@/utilities/replaceAbbreviations';
import classnames from 'classnames';

export enum CardSliderItemPosition {
  First = 'CardSliderItem--first',
  Second = 'CardSliderItem--second',
  Third = 'CardSliderItem--third',
  Hidden = 'CardSliderItem--hidden',
}

export interface CardSliderItemProps extends CmsComponent {
  title: string;
  shortTitle?: string;
  text: string;
  labelNext?: string;
  labelPrev?: string;
  position: CardSliderItemPosition;
}

export function CardSliderItem({ title, text, position }: CardSliderItemProps) {
  return (
    <section className={classnames('CardSliderItem', position)}>
      <div className="CardSliderItem__container">
        <div className="CardSliderItem__content">
          <strong>{title}</strong>
          <div
            className="CardSliderItem__text"
            dangerouslySetInnerHTML={replaceAbbreviations(text)}
          />
        </div>
      </div>
    </section>
  );
}
