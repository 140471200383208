import { Icon, IconVariant } from '@/components/Icon';
import replaceAbbreviations from '@/utilities/replaceAbbreviations';

const IconListItem = ({
  icon,
  title,
  text,
}: {
  icon?: IconVariant;
  title: string;
  text: string;
}) => {
  return (
    <li className="IconListItem">
      <figure className="IconListItem__icon">
        {icon && <Icon variant={icon} />}
      </figure>
      <p className="IconListItem__text">
        <strong>{title}</strong>
        <span dangerouslySetInnerHTML={replaceAbbreviations(text)} />
      </p>
    </li>
  );
};

export default IconListItem;
