import Area from '@/components/Area';
import { CmsArea } from '@/components/CmsInterfaces';
import React from 'react';

export interface IconListProps {
  contents?: CmsArea;
}

export const IconList: React.FC<React.PropsWithChildren<IconListProps>> = ({
  contents,
}) => {
  return <ul className="IconList">{contents && <Area {...contents} />}</ul>;
};

export default IconList;
