import classnames from 'classnames';

export const PageHeading = ({
  value,
  className,
}: {
  value: string;
  className?: string;
}) => {
  return (
    <h1
      className={classnames('Layout__title', {
        [`${className}`]: !!className,
      })}
    >
      {value}
    </h1>
  );
};
