import Area from '@/components/Area';
import { CmsArea } from '@/components/CmsInterfaces';
import React from 'react';

export interface TilesGameTeaserProps {
  contents?: CmsArea;
}

export const TilesGameTeaser: React.FC<
  React.PropsWithChildren<TilesGameTeaserProps>
> = ({ contents }) => {
  return (
    <div className="TilesGameTeaser">{contents && <Area {...contents} />}</div>
  );
};

export default TilesGameTeaser;
