import Logo from '@/components/Logo';
import { LogoVariant } from '@/components/LogoVariant';
import replaceAbbreviations from '@/utilities/replaceAbbreviations';
import classnames from 'classnames';
import React from 'react';

export interface TilesGameTeaserHeaderProps {
  theme: string;
  jackpot: string;
  subtitle: string;
  chance: string;
  logo?: LogoVariant;
}

export const TilesGameTeaserHeader: React.FC<
  React.PropsWithChildren<TilesGameTeaserHeaderProps>
> = ({ theme, jackpot, subtitle, chance, logo }) => {
  return (
    <header
      className={classnames(
        'TilesGameTeaserHeader',
        `TilesGameTeaserHeader--${theme}`
      )}
    >
      <div className="TilesGameTeaserHeader__container">
        {logo && (
          <Logo variant={logo} className="TilesGameTeaserHeader__logo" />
        )}
        <span
          className="TilesGameTeaserHeader__subtitle"
          dangerouslySetInnerHTML={replaceAbbreviations(subtitle)}
        />
        <strong className="TilesGameTeaserHeader__jackpot">{jackpot}</strong>
        <small
          className="TilesGameTeaserHeader__chance"
          dangerouslySetInnerHTML={replaceAbbreviations(chance)}
        />
      </div>
    </header>
  );
};

export default TilesGameTeaserHeader;
