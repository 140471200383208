import Button from '@/components/Button';
import { ButtonVariant } from '@/components/ButtonVariant';
import { CmsLink } from '@/components/CmsInterfaces';
import { GameTeaserProps, useGameTeaser } from '@/components/GameTeaser';
import { Icon, IconVariant } from '@/components/Icon';
import LinkWrapper from '@/components/LinkWrapper';
import TilesGameTeaserHeader, {
  TilesGameTeaserHeaderProps,
} from '@/components/TilesGameTeaserHeader';
import { getGameById } from '@/helpers/getGameById';

export type TilesGameTeaserItemProps = GameTeaserProps<{
  title: string;
  text: string;
  link?: CmsLink;
}>;

export const TilesGameTeaserItem = (props: TilesGameTeaserItemProps) => {
  const { config, state, theme } = useGameTeaser(props);

  const headerProps: TilesGameTeaserHeaderProps = {
    theme: theme,
    jackpot: state.jackpot,
    subtitle: state.subtitle,
    chance: config ? config.chanceDesc : '',
    logo: config && config.logo,
  };

  const currentGame = props.game ? getGameById(props.game) : null;
  const ariaLabel = currentGame
    ? `Mehr erfahren zu ${currentGame.title}`
    : `Mehr erfahren`;

  const href: string = props.link ? props.link.path || '#' : '#';
  return (
    <div className="TilesGameTeaserItem">
      <LinkWrapper href={href} tabIndex={-1}>
        <TilesGameTeaserHeader {...headerProps} />
      </LinkWrapper>
      <div className="TilesGameTeaserItem__content">
        <strong>{props.title || ''}</strong>
        <p>{props.text || ''}</p>
        <LinkWrapper href={href} tabIndex={-1}>
          <Button variant={ButtonVariant.Text} ariaLabel={ariaLabel}>
            <span>Mehr erfahren</span>
            <Icon variant={IconVariant.ArrowRight} />
          </Button>
        </LinkWrapper>
      </div>
    </div>
  );
};

export default TilesGameTeaserItem;
