import CardSlider from '@/components/CardSlider';
import DrawVideo, { DrawVideoProps } from '@/components/DrawVideo';
import { GameTeaserProps } from '@/components/GameTeaser';
import IconList, { IconListProps } from '@/components/IconList';
import IconListItem from '@/components/IconListItem';
import { LinkGame } from '@/components/LinkGame';
import NewsTeaser, { NewsTeaserProps } from '@/components/NewsTeaser';
import { Richtext } from '@/components/Richtext';
import Section from '@/components/Section';
import TilesGameTeaser, {
  TilesGameTeaserProps,
} from '@/components/TilesGameTeaser';
import TilesGameTeaserItem, {
  TilesGameTeaserItemProps,
} from '@/components/TilesGameTeaserItem';
import WinningNumbersContainer, {
  WinningNumbersContainerProps,
} from '@/components/WinningNumbersContainer';
import { PageHeading } from './PageHeading';
import Video, { VideoProps } from './Video';

export const DynamicComponent = (node) => {
  const templateName = node.template.replace(/^.*:components\//, '');

  function id(node: object) {
    return JSON.stringify(node);
  }

  switch (templateName) {
    case 'richtext':
      return (
        <Richtext key={id(node)} html={true}>
          {node.text}
        </Richtext>
      );

    case 'pageHeading':
      return <PageHeading key={id(node)} {...node} />;
  
    case 'linkGame':
      return <LinkGame key={id(node)} {...node} />;
    
    case 'section':
      return <Section key={id(node)} {...node} />;

    case 'iconList':
      return <IconList key={id(node)} {...(node as IconListProps)} />;

    case 'iconListItem':
      return <IconListItem key={id(node)} {...node} />;

    case 'cardSlider':
      return <CardSlider key={id(node)} {...node} />;

    case 'tilesGameTeaser':
      return (
        <TilesGameTeaser key={id(node)} {...(node as TilesGameTeaserProps)} />
      );

    case 'tilesGameTeaserItem':
      return (
        <TilesGameTeaserItem
          key={id(node)}
          {...(node as GameTeaserProps<TilesGameTeaserItemProps>)}
        />
      );

    case 'winningNumbers':
      return (
        <WinningNumbersContainer
          key={id(node)}
          showDate={true}
          showFooter={true}
          showTwoDraws={false}
          {...(node as WinningNumbersContainerProps)}
        />
      );

    case 'drawVideo':
      return <DrawVideo key={id(node)} {...(node as DrawVideoProps)} />;

    case 'youtubeVideo':
      return <Video key={id(node)} {...(node as VideoProps)} />;

    case 'newsTeaser':
      return <NewsTeaser key={id(node)} {...(node as NewsTeaserProps)} />;

    default:
      console.error(templateName);
      return <p key={id(node)}>No support for: {templateName}</p>;
  }
};
