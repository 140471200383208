import Area from '@/components/Area';
import Button from '@/components/Button';
import { ButtonVariant } from '@/components/ButtonVariant';
import { CmsArea, CmsComponent, CmsLink } from '@/components/CmsInterfaces';
import LinkWrapper from '@/components/LinkWrapper';
import classnames from 'classnames';
import React from 'react';

export interface SectionProps extends CmsComponent {
  title?: string;
  link?: CmsLink;
  linkLabel?: string;
  hideLine?: boolean;
  contents: CmsArea;
}

export class Section extends React.Component<SectionProps> {
  private renderLink = (): JSX.Element | null => {
    const { link, linkLabel } = this.props;
    return link !== undefined && linkLabel !== undefined ? (
      <LinkWrapper className="Section__link" href={link.path}>
        <Button variant={ButtonVariant.Primary}>{linkLabel}</Button>
      </LinkWrapper>
    ) : null;
  };

  private renderTitle = (): JSX.Element | null => {
    const { title } = this.props;
    return title !== undefined && title !== '' ? (
      <h2 className="Section__headline headline">
        {title}
        {this.renderLink()}
      </h2>
    ) : null;
  };

  render(): JSX.Element {
    const { contents, hideLine } = this.props;
    return (
      <section
        className={classnames('Section', {
          'Section--no-border': hideLine === true,
        })}
      >
        {this.renderTitle()}
        <div className="Section__content">
          {contents && <Area {...contents} />}
        </div>
        <footer className="Section__footer">{this.renderLink()}</footer>
      </section>
    );
  }
}

export default Section;
