import Area from '@/components/Area';
import { CmsArea, CmsPage } from '@/components/CmsInterfaces';
import Footer from '@/components/Footer';
import Header from '@/components/Header';
import { Layout, LayoutVariant } from '@/components/Layout';
import Page from '@/components/Page';
import classnames from 'classnames';
import React from 'react';

interface PageProductProps extends CmsPage {
  title: string;
  theme: string;
  contents: CmsArea;
  children?: React.ReactNode;
}

export default class PageProduct extends Page<PageProductProps> {
  public render(): JSX.Element {
    const { theme, contents, path } = this.props;
    return (
      <main
        className={classnames('page', 'page--product', {
          [`page--${theme}`]: !!theme,
        })}
      >
        <Header parentPage={theme} />
        <Layout variant={LayoutVariant.Product} theme={theme} path={path}>
          {contents && <Area {...contents} />}
        </Layout>
        <Footer />
      </main>
    );
  }
}
