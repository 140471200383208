import Button from '@/components/Button';
import { ButtonVariant } from '@/components/ButtonVariant';
import {
  CardSliderItem,
  CardSliderItemPosition,
  CardSliderItemProps,
} from '@/components/CardSliderItem';
import { CmsArea } from '@/components/CmsInterfaces';
import { Icon, IconVariant } from '@/components/Icon';
import classnames from 'classnames';
import React from 'react';

enum Direction {
  Forward = 1,
  Backward = -1,
}

export interface CardSliderProps {
  contents?: CmsArea;
}

interface CardSliderState {
  currentItem: number;
}

export class CardSlider extends React.Component<
  CardSliderProps,
  CardSliderState
> {
  constructor(props) {
    super(props);
    this.state = {
      currentItem: 0,
    };
  }

  private move = (direction: Direction): void => {
    this.setState((oldState) => ({
      currentItem: this.limitItemIndex(oldState.currentItem + direction),
    }));
  };

  private limitItemIndex = (index: number): number => {
    return Math.max(0, Math.min(this.items.length - 1, index));
  };

  private handleOnPrevClick = (): void => {
    this.move(Direction.Backward);
  };

  private handleOnNextClick = (): void => {
    this.move(Direction.Forward);
  };

  private getItemPosition = (index: number): CardSliderItemPosition => {
    const { currentItem } = this.state;
    switch (index) {
      case currentItem: {
        return CardSliderItemPosition.First;
      }
      case currentItem + 1: {
        return CardSliderItemPosition.Second;
      }
      case currentItem + 2: {
        return CardSliderItemPosition.Third;
      }
      default: {
        return CardSliderItemPosition.Hidden;
      }
    }
  };
  private getButtonLabel = (direction: Direction): string => {
    const forward = direction === Direction.Forward;
    const index = this.limitItemIndex(this.state.currentItem + direction);
    const item = this.items[index];
    if (item) {
      const preferred = forward ? item.labelNext : item.labelPrev;
      return preferred || item.shortTitle || item.title;
    }
    return '';
  };
  private ControlButton = (direction: Direction): JSX.Element => {
    const forward = direction === Direction.Forward;
    const element = forward ? 'next' : 'prev';
    const visible = (forward && this.hasNext) || (!forward && this.hasPrev);
    return (
      <Button
        disabled={!visible}
        variant={forward ? ButtonVariant.Primary : ButtonVariant.Secondary}
        onClick={forward ? this.handleOnNextClick : this.handleOnPrevClick}
        className={classnames(`CardSlider__${element}`, {
          [`CardSlider__${element}--hidden`]: !visible,
        })}
        ariaLabel={this.getButtonLabel(direction)}
      >
        {!forward && <Icon variant={IconVariant.ArrowLeft} />}
        <span>{this.getButtonLabel(direction)}</span>
        {forward && <Icon variant={IconVariant.ArrowRight} />}
      </Button>
    );
  };

  private get items(): CardSliderItemProps[] {
    const items = (this.props.contents && this.props.contents.nodes) || [];
    return items as CardSliderItemProps[];
  }

  private get hasNext(): boolean {
    return this.state.currentItem < this.items.length - 1;
  }

  private get hasPrev(): boolean {
    return this.state.currentItem > 0;
  }

  render(): JSX.Element {
    return (
      <article className="CardSlider">
        <div className="CardSlider__container">
          {this.items
            .map((item: CardSliderItemProps, i: number) => (
              <CardSliderItem
                {...item}
                key={i}
                position={this.getItemPosition(i)}
              />
            ))
            .reverse()}
        </div>
        <div className="CardSlider__controls">
          {this.ControlButton(Direction.Backward)}
          {this.ControlButton(Direction.Forward)}
        </div>
      </article>
    );
  }
}

export default CardSlider;
